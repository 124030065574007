export const useIsDevice = (screenSize: number) => {
  const isDevice = ref(false)

  const handleResize = () => {
    isDevice.value = window.innerWidth <= screenSize
  }

  onMounted(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
  })

  onUnmounted(() => {
    window.removeEventListener('resize', handleResize)
  })
  return { isDevice }
}
