<template>
  <LayoutSectionGeneric class-name="container-lg" :title="title">
    <div class="container-lg">
      <p class="text-base lg:text-xl font-bold mt-4">
        eBikes
      </p>
    </div>
    <div class="scroll-container scroll-container-lg -mt-16">
      <slot name="ebikesCard" />
    </div>
    <div class="w-full flex justify-center -mt-16">
      <ButtonsPrimaryButton
        slug="/instaride/listing/bike"
        :content="$t('components.buttons.ebikesBtn')"
      />
    </div>
    <div class="container-lg">
      <p class="text-base lg:text-xl font-bold mt-4">
        eScooters
      </p>
    </div>
    <div class="scroll-container scroll-container-lg -mt-16">
      <slot name="escootersCard" />
    </div>
    <div class="w-full flex justify-center -mt-16">
      <ButtonsPrimaryButton
        slug="/instaride/listing/scooter"
        :content="$t('components.buttons.escootersBtn')"
      />
    </div>
  </LayoutSectionGeneric>
</template>

<script>
export default {
  name: 'InstaridePromoted',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
}
</script>
