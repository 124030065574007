<template>
  <div class="container-xl p-0 mobile-container deals-wrapper d-flex justify-content-between align-items-end">
    <div class="card-wrapper-scroll vehicle-deals-wrapper deals-filters row">
      <slot name="vehicleDealsCard" />
    </div>
    <div v-if="withSort" class="deals-filters-sort d-none d-lg-block">
      <slot name="vehicleDealsSort" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DealsFilters',
  props: {
    withSort: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
