<template>
  <CardsListingBase img="info-card" alt="info card">
    <template #content>
      <div class="font-black italic text-center flex flex-col items-center">
        <div class="text-2xl">
          <span class="text-primary">5500+</span> {{ $t('components.cards.vehicles') }}
        </div>
        <div class="text-xl">
          {{ $t('components.cards.greece') }}
        </div>
      </div>
      <img src="/images/logo.svg" alt="instacar logo" width="98" height="18">
    </template>
  </CardsListingBase>
</template>
