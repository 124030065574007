<script setup>
const props = defineProps({
  lottieRef: { type: String, required: true },
  className: { type: String, default: null },
  filename: { type: String, required: true },
  loopAnimation: { type: Boolean, default: false },
})
const lottieElement = ref({})
const loadLottie = async (lottie) => {
  const response = await fetch(`/animations/${props.filename}.json`)
  const animationData = await response.json()
  const animation = lottie.loadAnimation({
    container: lottieElement.value.anim,
    loop: props.loopAnimation,
    autoplay: true,
    animationData,
  })

  animation.play()
}

onMounted(() => {
  import('lottie-web').then((module) => {
    loadLottie(module)
  })
})
</script>

<template>
  <div>
    <div
      :ref="(el) => lottieElement[lottieRef] = el"
      :class="className"
    />
  </div>
</template>
