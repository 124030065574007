<template>
  <button class="btn btn-reg btn-primary rounded-[32px] btn-hover-shadow-primary" @click.stop="callback">
    <span>{{ content }}</span>
    <span v-if="hasArrow">
      <VectorsChevronRight />
    </span>
  </button>
</template>

<script>
export default {
  name: 'PrimaryButton',
  props: {
    content: {
      type: String,
      required: true,
    },
    callback: {
      type: Function,
      required: true,
    },
    hasArrow: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
