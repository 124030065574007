<template>
  <LayoutSectionGeneric class-name="container-lg">
    <div class="flex flex-col-reverse lg:flex-row items-center justify-center lg:justify-between">
      <img class="w-full max-w-[620px] lg:w-1/2 -mt-12 lg:-mt-0" src="/images/home/comparison.webp" alt="Comparison image" width="550" height="440">
      <div class="flex flex-col items-center lg:items-start gap-4">
        <span class="text-center lg:text-left max-w-sm lg:max-w-full font-bold heading pb-4">{{ $t('components.comparisonTable.title') }}</span>
        <CardsComparison class="w-full max-w-[580px]" />
      </div>
    </div>
  </LayoutSectionGeneric>
</template>
