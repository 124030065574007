
// @ts-nocheck


export const localeCodes =  [
  "el",
  "en"
]

export const localeLoaders = {
  "el": [],
  "en": []
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "el",
      "name": "GR",
      "language": "el"
    },
    {
      "code": "en",
      "name": "EN",
      "language": "en"
    }
  ],
  "defaultLocale": "el",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "https://www.instacar.gr",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "leasing/metaxeirismena/index": {
      "en": "/leasing/cars"
    },
    "buy/metaxeirismena/index": {
      "en": "/buy/used-cars"
    },
    "leasing/metaxeirismena/ilektrika-uvridika": {
      "en": "/leasing/cars/electric-hybrid"
    },
    "leasing/metaxeirismena/venzini": {
      "en": "/leasing/cars/gasoline"
    },
    "leasing/metaxeirismena/petrelaio": {
      "en": "/leasing/cars/diesel"
    },
    "leasing/metaxeirismena/ugraerio": {
      "en": "/leasing/cars/lpg"
    },
    "leasing/metaxeirismena/fysiko-aerio": {
      "en": "/leasing/cars/natural-gas"
    },
    "leasing/metaxeirismena/automato": {
      "en": "/leasing/cars/automatic"
    },
    "leasing/metaxeirismena/mikra": {
      "en": "/leasing/cars/small"
    },
    "leasing/metaxeirismena/mesaia": {
      "en": "/leasing/cars/mid-size"
    },
    "leasing/metaxeirismena/megala": {
      "en": "/leasing/cars/large"
    },
    "leasing/metaxeirismena/epaggelmatika": {
      "en": "/leasing/cars/commercial-vehicles"
    },
    "leasing/metaxeirismena/idiotes": {
      "en": "/leasing/cars/individuals"
    },
    "leasing/metaxeirismena/prosfores": {
      "en": "/leasing/cars/special-offers"
    },
    "leasing/metaxeirismena/xeirokinito": {
      "en": "/leasing/cars/manual"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "el",
    "name": "GR",
    "language": "el",
    "files": []
  },
  {
    "code": "en",
    "name": "EN",
    "language": "en",
    "files": []
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
