<template>
  <ButtonsInstaLink class-name="btn btn-reg btn-secondary btn-hover-shadow-secondary rounded-full" :slug="slug" :new-tab="newTab">
    <span>{{ content }}</span>
    <span v-if="hasArrow">
      <VectorsChevronRight />
    </span>
  </ButtonsInstaLink>
</template>

<script>
export default {
  name: 'SecondaryButton',
  props: {
    slug: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    newTab: {
      type: Boolean,
      default: false,
    },
    hasArrow: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
